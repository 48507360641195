<template>
  <div>
    <Echart
      :options="options"
      id="centreLeft1Chart"
      height="1.8rem"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from "../../../common/echart/index.vue";
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(val) {
        this.options = {
          tooltip: {
            trigger: "item",
          },
          legend: {
            show: false,
            top: "5%",
            left: "center",
          },
          color: ["#FCB964", "#68A8FF"],
          series: [
            {
              type: "pie",
              center: ["50%", "50%"],
              radius: ["50%", "70%"],
              avoidLabelOverlap: false,
              label: {
                normal: {
                  textStyle: {
                    fontSize: 12,
                    color: "#f1f1fe",
                  },
                },
              },
              data: val.pieData,
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>