<template>
  <div>
    <Echart
      :options="options"
      id="centreLeft1Chart"
      height="4.35rem"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from "../../../common/echart/index.vue";
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        console.log(newData);
        this.options = {
          // grid: {
          //   top: "15%",
          //   left: "10%",
          //   right: "10%",
          //   bottom: "15%",
          //   containLabel: true,
          // },
          // xAxis: {
          //   type: "category",
          //   data: ["足特园", "公益园", "示范园", "普通园"],
          //   axisLine: {
          //     show: true,
          //     lineStyle: {
          //       color: "#A4E2FC",
          //       opacity: 0.3,
          //     },
          //   },
          //   axisLabel: {
          //     rotate: 0,
          //     show: true,
          //     textStyle: {
          //       fontSize: 12,
          //       color: "#BDD1DA",
          //     },
          //   },
          // },
          // yAxis: {
          //   type: "value",
          //   axisLine: {
          //     show: true,
          //     lineStyle: {
          //       color: "#A4E2FC",
          //       opacity: 0.3,
          //     },
          //   },
          //   axisLabel: {
          //     rotate: 0,
          //     show: true,
          //     textStyle: {
          //       fontSize: 12,
          //       color: "#BDD1DA",
          //     },
          //   },
          // },
          // series: [
          //   {
          //     data: [120, 200, 150, 80],
          //     type: "bar",
          //     barWidth: 20,
          //     itemStyle: {
          //       normal: {
          //         color: "#BAFDFB", //柱状的颜色
          //         label: {
          //           textStyle: {
          //             fontSize: "15", //柱状上的显示的文字
          //             color: "#ccecff",
          //           },
          //         },
          //       },
          //     },
          //   },
          // ],
          legend: {
            bottom: "10%",
            data: ["足特园", "公益园", "示范园", "普通园", "注册园所"],
            textStyle: {
              color: "#fff",
              fontSize: 12,
            },
          },
          color: ["#F78C6F", "#FDC964", "#E5ACFF", "#9FD1FF", "#BAFDFB"],
          textStyle: {
            fontSize: 12,
            fontStyle: "normal",
          },
          tooltip: {
            trigger: "item",
            formatter: "{b} : {c}",
          },
          series: [
            {
              type: "pie",
              radius: [10, 80],
              center: ["50%", "40%"],
              roseType: "area",
              label: {
                normal: {
                  textStyle: {
                    fontSize: 12,
                    color: "#f1f1fe",
                  },
                },
              },
              itemStyle: {
                borderRadius: 5,
              },
              data: newData.pieData,
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>