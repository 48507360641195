<template>
  <div>
    <Echart
      :options="options"
      id="centreLeft1Chart"
      height="3.35rem"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from "../../../common/echart/index.vue";
export default {
  data() {
    return {
      options: {},
      // 定义颜色
      colorList: {
        linearYtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#B4EAEB",
            },
            {
              offset: 1,
              color: "#B4EAEB",
            },
          ],
        },
        linearGtoB: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#43dfa2",
            },
            {
              offset: 1,
              color: "#28f8de",
            },
          ],
        },
        linearBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#1c98e8",
            },
            {
              offset: 1,
              color: "#28f8de",
            },
          ],
        },
        areaBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "rgba(35,184,210,.2)",
            },
            {
              offset: 1,
              color: "rgba(35,184,210,0)",
            },
          ],
        },
      },
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          title: {
            text: "量统计(人/次)",
            textStyle: {
              fontSize: 14,
              color: "#85B6CA",
            },
            top: "3%",
            left: "3%",
          },
          grid: {
            left: "5%",
            right: "5%",
            bottom: "25%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            position: "bottom",
            axisLine: true,
            axisLabel: {
              color: "rgba(255,255,255,.8)",
              fontSize: 12,
            },
            data: newData.weekCategory,
          },
          // 下方Y轴
          yAxis: {
            name: "",
            nameLocation: "end",
            nameGap: 24,
            nameTextStyle: {
              color: "rgba(255,255,255,.5)",
              fontSize: 14,
            },
            max: newData.maxData,
            splitNumber: 4,

            axisLine: {
              lineStyle: {
                opacity: 0,
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#fff",
                opacity: 0.1,
              },
            },
            axisLabel: {
              color: "rgba(255,255,255,.8)",
              fontSize: 12,
            },
          },
          series: [
            {
              type: "line",
              smooth: true,
              symbol: "emptyCircle",
              symbolSize: 8,
              itemStyle: {
                normal: {
                  color: "#fff",
                },
              },
              lineStyle: {
                normal: {
                  color: this.colorList.linearBtoG,
                  width: 3,
                },
              },
              areaStyle: {
                normal: {
                  color: this.colorList.areaBtoG,
                },
              },
              data: newData.weekLineData,
              lineSmooth: true,

              tooltip: {
                position: "top",
                formatter: "{c} m",
                backgroundColor: "rgba(28,152,232,.2)",
                padding: 6,
              },
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>