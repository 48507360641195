<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <!-- 头部head -->
        <div class="d-flex head">
          <div class="head-code" @click="bindcode">
            <img src="../../assets/img/datav/full/Pic_ShutDown.png" alt="" />
          </div>
          <div class="d-flex flex-1 jc-between head-title">
            <div class="dateYear">
              <dv-decoration-10
                style="width: 3.5rem; height: 0.38rem"
                :color="['#71c9f4']"
              />

              <span class="date">{{ dateYear }} {{ dateDay }}</span>
            </div>

            <div class="head-name">
              <img
                src="../../assets/img/datav/full/Pic_Title_Line.png"
                alt=""
              />
            </div>
            <div class="dateDay">
              <dv-decoration-10
                :color="['#71c9f4']"
                style="
                  width: 3.5rem;
                  height: 0.38rem;
                  transform: rotateY(180deg);
                "
              />

              <el-cascader
                ref="cascaderUnit"
                v-model="value"
                :options="options"
                :props="{ checkStrictly: true }"
                @change="handleChange"
                v-if="$store.state.superviseId"
              ></el-cascader>
              <div v-else>{{ province }}/{{ city }}</div>
            </div>
          </div>
          <div class="head-full" @click="clickFullscreen()">
            <img src="../../assets/img/datav/full/Pic_QP.png" alt="" />
          </div>
        </div>

        <!-- 内容 -->
        <div class="body-box">
          <div class="left-box">
            <div class="left-box-1">
              <dv-border-box-1>
                <div class="box-title">
                  <img
                    src="../../assets/img/datav/full/Pic_QGRZRS.png"
                    alt=""
                  />
                </div>
                <flop />
                <lineChart />
              </dv-border-box-1>
            </div>
            <div class="left-box-2">
              <dv-border-box-1>
                <div class="box-title">
                  <img src="../../assets/img/datav/full/Pic_XYHYB.png" alt="" />
                </div>
                <pieRadChart />
                <img
                  src="../../assets/img/datav/full/Pic_Line.png"
                  alt=""
                  style="width: 100%; height: 0.14rem"
                />
                <barChart />
              </dv-border-box-1>
            </div>
          </div>

          <div class="content-box">
            <div class="content-box-1">
              <dv-border-box-1>
                <div class="box-title">
                  <img
                    src="../../assets/img/datav/full/Pic_QSHLRDTJ.png"
                    alt=""
                  />
                </div>
                <mapChart :province="province" :city="city" />
              </dv-border-box-1>
            </div>

            <div class="content-box-2">
              <dv-border-box-1>
                <div class="box-title">
                  <img
                    src="../../assets/img/datav/full/Pic_SSSKBB.png"
                    alt=""
                  />
                </div>
                <broadcast />
              </dv-border-box-1>
            </div>
          </div>

          <div class="right-box">
            <div class="right-box-1">
              <dv-border-box-1>
                <div class="box-title">
                  <img
                    src="../../assets/img/datav/full/Pic_QGYSFB.png"
                    alt=""
                  />
                </div>
                <pieChart />
              </dv-border-box-1>
            </div>

            <div class="right-box-2">
              <dv-border-box-1>
                <div class="box-title">
                  <img
                    src="../../assets/img/datav/full/Pic_Title_QGPJYDNLFX.png"
                    alt=""
                  />
                </div>
                <radarChart />
              </dv-border-box-1>
            </div>
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import screenfull from "screenfull"; // 引入大屏插件
import { formatTime } from "@/utils/tools.js";
import radarChart from "@/components/datav/echartRight/radar"; // 雷达
import pieChart from "@/components/datav/echartRight/pie"; // 饼图
import lineChart from "@/components/datav/echartLeft/line"; // 折线
import pieRadChart from "@/components/datav/echartLeft/pieRad"; // 饼图圆
import barChart from "@/components/datav/echartLeft/bar"; // 饼图圆

import flop from "@/components/datav/echartLeft/flop.vue"; // 滚动文字

import mapChart from "@/components/datav/echartCenter/map"; // 饼图圆

import broadcast from "@/components/datav/echartCenter/broadcast"; // 饼图圆
export default {
  data() {
    return {
      isFullscreen: false,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      value: "",
      options: [],
      province: this.$store.state.schoolInfo.atteSchool.province, // 省份
      city: this.$store.state.schoolInfo.atteSchool.trueCity, // 城市
    };
  },
  components: {
    radarChart,
    pieChart,
    lineChart,
    pieRadChart,
    barChart,
    flop,
    mapChart,
    broadcast,
  },
  mounted() {
    this.timeFn();
    this.cancelLoading();
    // this.drawTimingFn();
    this.cascaded();
    this.setData(this.province, this.city);
    setInterval(function () {
      document.querySelectorAll(".el-cascader-node__label").forEach((el) => {
        el.onclick = function () {
          if (this.previousElementSibling) this.previousElementSibling.click();
        };
      });
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.drawTiming);
  },
  methods: {
    handleChange() {
      const checkedNodes = this.$refs["cascaderUnit"].getCheckedNodes();
      console.log(checkedNodes); // 获取当前点击的节点
      console.log(checkedNodes[0].data.label); // 获取当前点击的节点的label
      console.log(checkedNodes[0].pathLabels); // 获取由 label 组成的数组
      this.province = checkedNodes[0].pathLabels[0];
      this.city = checkedNodes[0].pathLabels[1];
      this.setData(this.province, this.city);
    },
    bindcode() {
      this.$router.go(-1);
    },
    // 获取三级城市联动
    cascaded() {
      this.api.datav.cascaded().then((res) => {
        console.log(res, "获取三级城市联动");
        this.options = res.data;
      });
    },
    drawTimingFn() {
      this.drawTiming = setInterval(() => {
        this.setData(this.province, this.city);
      }, 6000);
    },
    setData(province, city) {
      let data = {
        province: province,
        city: city,
      };
      this.$store.dispatch("getFullData", data);
    },
    timeFn() {
      setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    clickFullscreen() {
      if (!screenfull.isEnabled) {
        this.$message({
          message: "you browser can not work",
          type: "warning",
        });
        return false;
      }
      screenfull.toggle();
    },
  },
  watch: {
    value() {
      if (this.$refs.cascaderUnit) {
        this.$refs.cascaderUnit.dropDownVisible = false; //监听值发生变化就关闭它
      }
    },
  },
};
</script>

<style scoped lang="scss">
#index {
  color: #d3d6dd;
  background-color: #000000;
  width: 100%;
  height: 100%;

  .bg {
    background-image: url("../../assets/img/datav/full/BG_All.png");
    background-size: cover;
    background-position: center center;
  }

  .host-body {
    .head {
      width: 100%;
      height: 72px;
      background: url("../../assets/img/datav/full/Pic_NV_Bg.png") no-repeat;
      background-size: 100% 100%;
      .date {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #c8ddff;
      }
      .dateYear {
        text-align: right;
      }
      .head-name {
        position: relative;
        width: 670px;
        height: 72px;
        img {
          width: 670px;
          height: 72px;
        }
      }
      .head-full {
        cursor: pointer;
        img {
          width: 272px;
          height: 72px;
        }
      }
      .head-code {
        cursor: pointer;
        img {
          width: 272px;
          height: 72px;
        }
      }
    }
    .body-box {
      margin-top: 0.2rem;
      display: flex;
      justify-content: space-between;
      .left-box {
        width: 480px;
        .left-box-1 {
          width: 480px;
          height: 491px;
          background: url("../../assets/img/datav/full/bg2.png") no-repeat;
          background-size: 100% 100%;
        }

        .left-box-2 {
          width: 480px;
          height: 491px;
          background: url("../../assets/img/datav/full/bg2.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .content-box {
        width: 919px;
        .content-box-1 {
          width: 100%;
          height: 615px;
          background: url("../../assets/img/datav/full/bg4.png") no-repeat;
          background-size: 100% 100%;
        }

        .content-box-2 {
          width: 100%;
          height: 369px;
          background: url("../../assets/img/datav/full/bg3.png") no-repeat;
          background-size: 100% 100%;
        }
      }

      .right-box {
        width: 480px;
        .right-box-1 {
          width: 100%;
          height: 491px;
          background: url("../../assets/img/datav/full/bg2.png") no-repeat;
          background-size: 100% 100%;
        }

        .right-box-2 {
          width: 100%;
          height: 491px;
          background: url("../../assets/img/datav/full/bg2.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }

    .box-title {
      padding-top: 6px;
      padding-left: 13px;
      img {
        width: 100%;
        height: 55px;
      }
    }
  }
}
.el-cascader {
  position: absolute;
  top: 24px;
  /deep/ .el-input__inner {
    background: none !important;
    border: none !important;
    font-size: 0.16rem !important;
    font-family: Microsoft YaHei !important;
    font-weight: bold !important;
    color: #c8ddff !important;
  }
}
</style>