<template>
  <div class="echar-map">
    <Chart :province="province" :city="city" />
  </div>
</template>

<script>
import Chart from "./chart.vue";
export default {
  props: {
    province: {
      // 省份
      type: String,
      default: "",
    },
    city: {
      // 城市
      type: String,
      default: "",
    },
  },
  data() {
    return {
      cdata: [],
      ranData: [
        {
          name: "",
        },
      ],
    };
  },
  components: {
    Chart,
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.echar-map {
}
</style>