<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from "./chart.vue";
export default {
  data() {
    return {
      drawTiming: null,
      cdata: {
        cityData: this.$store.state.fulllData.top5data.name,
        serviceData: this.$store.state.fulllData.top5data.data,
      },
    };
  },
  components: {
    Chart,
  },
  mounted() {
    console.log(this.$store.state.fulllData, "22222222222222222222");
  },
  beforeDestroy() {},
  methods: {},
  watch: {
    "$store.state.fulllData.top5data": {
      handler(newVal) {
        console.log(newVal, "top5data");
        this.cdata.cityData = newVal.name;
        this.cdata.serviceData = newVal.data;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>