<template>
  <div class="flop">
    <div class="flop-box">
      共计
      <dv-digital-flop
        :config="config"
        style="width: 2.34rem; height: 0.85rem"
      />
      人
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      config: {
        number: [this.$store.state.fulllData.SchoolCountDaily.data[0]],
        content: "{nt}",
        style: {
          fontSize: 42,
          fill: "rgba(255, 215, 119, 0.9)",
          fontWeight: "bold",
          fontFamily: "Impact",
        },
      },
    };
  },
  computed: {},
  mounted() {
    console.log(this.$store.state.fulllData.SchoolCountDaily.data);
  },
  beforeDestroy() {},
  methods: {},
  watch: {
    "this.$store.state.fulllData.SchoolCountDaily": {
      handler(newVal) {
        console.log(newVal, "人数为什么还不改变呢");
        // 信息页面展示内容
        let obj = {
          number: [newVal[0]],
          content: "{nt}",
          style: {
            fontSize: 42,
            fill: "rgba(255, 215, 119, 0.9)",
            fontWeight: "bold",
            fontFamily: "Impact",
          },
        };
        this.config = { ...obj };
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.flop {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  padding-top: 30px;
}
.flop-box {
  display: flex;
  width: 379px;
  height: 85px;
  align-items: center;
  justify-content: center;
  background: url(../../../assets/img/datav/full/title_bg.png) no-repeat;
  background-size: 100% 100%;
}
</style>