<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from "./chart.vue";
export default {
  data() {
    return {
      drawTiming: null,
      cdata: {
        pieData: this.$store.state.fulllData.schoolKind,
      },
    };
  },
  components: {
    Chart,
  },
  mounted() {},
  beforeDestroy() {},
  methods: {},
  watch: {
    "$store.state.fulllData.schoolKind": {
      handler(newVal) {
        this.cdata.pieData = newVal;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>