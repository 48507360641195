<template>
  <div>
    <Echart
      :options="options"
      id="centreLeft1Chart"
      height="2.41rem"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from "../../../common/echart/index.vue";
export default {
  data() {
    return {
      options: {},
      // 定义颜色
      colorList: {
        linearYtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#B4EAEB",
            },
            {
              offset: 1,
              color: "#B4EAEB",
            },
          ],
        },
        linearGtoB: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#43dfa2",
            },
            {
              offset: 1,
              color: "#28f8de",
            },
          ],
        },
        linearBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#1c98e8",
            },
            {
              offset: 1,
              color: "#28f8de",
            },
          ],
        },
        areaBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "rgba(35,184,210,.2)",
            },
            {
              offset: 1,
              color: "rgba(35,184,210,0)",
            },
          ],
        },
      },
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(newVal) {
        console.log(newVal, "监测客源地统计(Top5)");
        this.options = {
          title: {
            text: "活跃统计",
            textStyle: {
              fontSize: 14,
              color: "#A4E2FC",
            },
            left: "3%",
            top: "3%",
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          grid: {
            top: "15%",
            left: "5%",
            right: "10%",
            bottom: "15%",
            containLabel: true,
          },
          xAxis: {
            type: "value",
            boundaryGap: [0, 0.02],
            axisLine: {
              show: true,
              lineStyle: {
                color: "#A4E2FC",
                opacity: 0.3,
              },
            },
            axisLabel: {
              rotate: 0,
              show: true,
              textStyle: {
                fontSize: 12,
                color: "#BDD1DA",
              },
            },
          },
          yAxis: {
            type: "category",
            data: newVal.cityData,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#A4E2FC",
                opacity: 0.3,
              },
            },
            axisLabel: {
              rotate: 0,
              show: true,
              textStyle: {
                fontSize: 12,
                color: "#BDD1DA",
              },
            },
          },
          series: [
            {
              name: "活跃地统计",
              type: "bar",
              data: newVal.serviceData,
              barWidth: 10,
              itemStyle: {
                normal: {
                  color: "#BAFDFB", //柱状的颜色
                  label: {
                    textStyle: {
                      fontSize: "15", //柱状上的显示的文字
                      color: "#ccecff",
                    },
                  },
                },
              },
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>