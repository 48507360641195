<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from "./chart.vue";
export default {
  data() {
    return {
      drawTiming: null,
      cdata: {
        year: null,
        weekCategory: this.$store.state.fulllData.SchoolCountDaily.date,
        radarData: [],
        radarDataAvg: [],
        maxData: 20000,
        weekMaxData: [],
        weekLineData: this.$store.state.fulllData.SchoolCountDaily.data,
      },
    };
  },
  components: {
    Chart,
  },
  mounted() {
    //this.drawTimingFn();
  },
  beforeDestroy() {
    //clearInterval(this.drawTiming);
  },
  methods: {},
  watch: {
    "$store.state.fulllData.SchoolCountDaily": {
      handler(newVal) {
        console.log(newVal, 111111111111111111);
        this.cdata.weekLineData = newVal.data;
        this.cdata.weekCategory = newVal.date;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>