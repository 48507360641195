<template>
  <div>
    <Echart
      :options="options"
      id="centreLeft1Chart"
      height="4.35rem"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from "../../../common/echart/index.vue";
export default {
  data() {
    return {
      options: {},
      // 定义颜色
      colorList: {
        linearYtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#B4EAEB",
            },
            {
              offset: 1,
              color: "#B4EAEB",
            },
          ],
        },
        linearGtoB: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#43dfa2",
            },
            {
              offset: 1,
              color: "#28f8de",
            },
          ],
        },
        linearBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#1c98e8",
            },
            {
              offset: 1,
              color: "#28f8de",
            },
          ],
        },
        areaBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "rgba(35,184,210,.2)",
            },
            {
              offset: 1,
              color: "rgba(35,184,210,0)",
            },
          ],
        },
      },
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler() {
        this.options = {
          tooltip: {
            trigger: "item",
          },
          radar: {
            center: ["50%", "50%"],
            radius: "60%",
            name: {
              color: "#fff",
            },
            splitNumber: 8,
            axisLine: {
              lineStyle: {
                color: this.colorList.linearYtoG,
                opacity: 0.6,
              },
            },
            splitLine: {
              lineStyle: {
                color: this.colorList.linearYtoG,
                opacity: 0.6,
              },
            },
            splitArea: {
              areaStyle: {
                color: "#fff",
                opacity: 0.1,
                shadowBlur: 25,
                shadowColor: "#000",
                shadowOffsetX: 0,
                shadowOffsetY: 5,
              },
            },
            indicator: [
              {
                name: "跑步",
                max: 20,
                color: "#B4EAEB",
              },
              {
                name: "跳跃",
                max: 20,
                color: "#B4EAEB",
              },
              {
                name: "平衡",
                max: 32,
                color: "#B4EAEB",
              },
              {
                name: "敏捷",
                max: 32,
                color: "#B4EAEB",
              },
              {
                name: "力量",
                max: 32,
                color: "#B4EAEB",
              },
              {
                name: "协调",
                max: 32,
                color: "#B4EAEB",
              },
              {
                name: "物体控制-手",
                max: 32,
                color: "#B4EAEB",
              },
              {
                name: "物体控制-脚",
                max: 32,
                color: "#B4EAEB",
              },
            ],
          },
          series: [
            {
              name: "平均运动能力分析",
              type: "radar",
              symbolSize: 0,
              data: [
                {
                  value: [15, 15, 16, 16, 17, 17, 17, 17],
                  itemStyle: {
                    normal: {
                      color: "#B4EAEB",
                    },
                  },
                  lineStyle: {
                    normal: {
                      opacity: 0,
                    },
                  },
                  areaStyle: {
                    normal: {
                      color: "#96fcf98c",
                      shadowBlur: 25,
                      shadowColor: "#B4EAEB",
                      shadowOffsetX: 0,
                      shadowOffsetY: 10,
                      opacity: 1,
                    },
                  },
                },
              ],
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>